// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
#stgType {
	height: 51px;
}


form.search-form { background:#fff; border-radius: 0.3rem; }
form.search-form .search-field { border:0; font-size:.8rem; height: 2rem; padding: 0.375rem 0.75rem; line-height: 1; }
form.search-form input[type="submit"] { background: #fff url('/wp-content/uploads/2023/05/magnify.png') no-repeat; text-indent: -9999em; border:0; border-radius: 0.3rem; padding: 0; width:30px; }
form.search-form .search-submit:hover {  background-color: #fff;  }	



/* GRAVITY FORMS */
@media screen and (min-width: 641px) {  
	.gform_wrapper textarea.large { height: 160px !important; width: 100%; }
	body .gform_wrapper ul.gfield_radio li { display: inline; padding-right: 10px!important; }  /* to display radio fields inline */
}
body .gform_wrapper ul li.field_description_below div.ginput_container_radio,
body .gform_wrapper ul li.field_description_below div.ginput_container_checkbox,
body .gform_wrapper.gf_browser_chrome .gfield_checkbox li input, 
body .gform_wrapper.gf_browser_chrome .gfield_checkbox li input[type=checkbox], 
body .gform_wrapper.gf_browser_chrome .gfield_radio li input[type=radio] { margin-top: 0; }





