.home {
	#hero { 
		background: $very-light-gray;
		margin:0;
		> .container { min-height:800px;  }
		h1 { 
			font-size: 3.5rem !important; margin-bottom: 1rem; margin-top: 3rem; font-weight: 300; line-height: 1; 
		}
		@include media-breakpoint-down(md) {  
			h1 { font-size: 2.5rem !important; } 
		}
		.btn { margin: 30px 0;  }
	}
	
	#services {
		.col-md-4 { margin-bottom: 30px; }	
		h2 {
			text-align: center; position:relative;
			font-size:1.5rem !important;
			font-weight: 400;
			margin: 1rem;
			a { color: #fff !important;  }
		}
		.box { margin-bottom: 1rem; }
		img { margin-bottom: 1rem; padding-bottom: 3rem; }
	} 
	
	#recent .block { 
		padding-bottom: 30px; 
		@include media-breakpoint-up(md) {  padding: 30px; } 
		h3 { 
			margin: 15px 0;  
			a { text-decoration:none;  } 	
			a:hover { text-decoration:underline;  }
		}
	}
	
	#carousel div.carousel-border {
    	border: 1px solid #909090;
    	padding: 20px 20px 90px;
		h2.title {
			background: #fff;
			position: relative;
			display: inline;
			padding: 0 20px;
			z-index: 50;
			top: -40px;
		}
		.slick-dots { bottom: -50px; }	
		.slick-dots li button:before {
			border: 2px solid #909090;
			background:#909090;
		}
		.slick-dots li.slick-active  button:before {
			border: 3px solid $gold;
			background: $gold;
		}
	}	

	#testimonials {
		h2 {
			margin-bottom: 3rem;
			position: relative;
		}
		h2:after {
			content:'';
			width: 2.5rem;
			height: 2px;
			background: $brand-secondary;
			position: absolute;
			left: 48%; /* temp */
			bottom: -0.75rem;
		}
		.slick-next::before, .slick-prev::before {
			color: $brand-secondary;
			font-size: 2rem;
		}
		.slick-prev { left: -4rem; }
		.slick-next { right: -4rem; }
	}


} /* end home page styles */	


/* CAREERS PAGE */
.career-post-sidebar { .btn { width:100%; } }
.career-post-footer { @include media-breakpoint-down(md) { display:none; } }


/* Roll up text */
.rollover-roll-text {
	.caption-wrapper {
		color: $white;
		position: absolute;
		max-width: 100%;
		height: 0;
		overflow: hidden;
		bottom: 0;
		left: 0;
		right: 0;
		text-align:center;
		background: rgba(0, 0, 0, 0.5);
		transition: height 0.3s;
		.caption-inner {
			position: absolute;
			top: 40%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
	    }
	}
	.caption-title {
		background: $brand-primary;	
		position:absolute; 
		bottom:0; 
		width:100%;
		z-index:5;
    }	
	&:hover {
    	.caption-wrapper { height: 100%; }
  	}
}




/*  media queries  */
@media (max-width: 1200px) {}
@media (max-width: 992px) {}
@media (max-width: 768px) {}
@media (max-width: 544px) {}

// Webkit
@-webkit-keyframes wobble  {
  0%  { -webkit-transform:  rotate(0deg); }
  20%  { -webkit-transform:  rotate(2deg); }
  50%  { -webkit-transform:  rotate(-2deg); }
  100%  { -webkit-transform:  rotate(0deg); }
}