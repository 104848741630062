#prefooter {
	background: $blue; 
	color: #fff;
	padding: 3rem 0;
	margin: 3rem 0 0 0;
}

footer.site-footer {
	background: $royal; 
	color:#fff;
	padding: 3rem 0;
	font-size: 15px; 
	div.label {  font-weight:700; font-style:italic; text-transform:uppercase;  }
	.copyright { padding-bottom: 0; padding-top: 1rem; font-size: 14px;  }
	
	ul.menu {
		list-style-type: none;
		padding: 0;
		li {  }
		li.bold > a { font-weight:700; font-style:italic; text-transform:uppercase; }	
		li.last { margin-bottom: 1.5rem; }  /* extra space in footer nav */			
		a { text-decoration:none; color:#fff; font-weight: 400;}
		a:hover { text-decoration: underline; }
		ul.sub-menu { padding:0; list-style-type:none }	
	}	
}

	
@media (max-width: 992px) {}
@media (max-width: 768px) {}
@media (max-width: 544px) {}